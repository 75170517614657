import dynamic from 'next/dynamic'
import SkeletonCard from '../../skeleton/card/skeleton-card'

// DON'T USE VARIABLE FOR DYNAMIC OPTIONS, CAUSE Did not expect server HTML to contain a <div> in <div>
/* const dynamicOptions = {
	loading: SkeletonCard,
} */

const TileTooltip = dynamic(() => import('@/components/ui/molecules/tile/tooltip'), {
	loading: SkeletonCard,
})

const TileLookAlternative = dynamic(() => import('@/components/ui/molecules/tile/look-alternative'), {
	loading: SkeletonCard,
})

const TileLookListing = dynamic(() => import('@/components/ui/molecules/tile/look-listing'), {
	loading: SkeletonCard,
})

const TileListing = dynamic(() => import('@/components/ui/molecules/tile/listing'), {
	loading: SkeletonCard,
})

export const customVariants = {
	tooltip: TileTooltip,
	lookAlternative: TileLookAlternative,
	lookListing: TileLookListing,
	listing: TileListing,
}
